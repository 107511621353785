import React, { FC } from 'react';
import { ContainerProps } from 'emotion-flex';
import styled from '@emotion/styled';
import { ReactComponent as WebinareSvg } from '../../assets/svgs/doctors/img-webinars.svg';
import { OpenSansParagraph, RecoletaHeading } from '../common/typography';
import { ButtonLink } from '../common/Button';
import { markdownText } from '../../pages/doctors';

interface WebinareCardProps {
  title: string;
  description: string;
  primaryCTAButtonText: string;
  primaryCTAButtonLink: string;
}

interface WebinareProps {
  title: string;
  description: string;
  items: WebinareCardProps[];
}

interface Props {
  webinare: WebinareProps;
}

const Container = styled('div')(
  {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingLeft: 24,
    paddingRight: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  ({ fluid }: ContainerProps) => ({
    maxWidth: fluid ? '100%' : '900px',
  })
);

const WebinareCard = (item: WebinareCardProps, index: number) => {
  const {
    title,
    description,
    primaryCTAButtonText,
    primaryCTAButtonLink,
  } = item;

  const getColour = (index: number) => {
    switch (index) {
      case 0:
        return '#F4F6D8';
      case 1:
        return '#DDF2F8';
      default:
        return '#F4F6D8';
    }
  };

  return (
    <div
      css={{
        padding: '1.5rem',
        backgroundColor: getColour(index),
        borderRadius: 20,
        width: 300,
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <RecoletaHeading
        css={{
          fontSize: 24,
        }}
      >
        {title}
      </RecoletaHeading>
      <OpenSansParagraph
        css={{
          minHeight: '30px',
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        {description}
      </OpenSansParagraph>
      <ButtonLink to={primaryCTAButtonLink}>{primaryCTAButtonText}</ButtonLink>
    </div>
  );
};

const Webinare: FC<Props> = ({ webinare }) => {
  const { title, description, items } = webinare;

  return (
    <Container>
      <div
        id="webinare"
        css={{
          marginTop: '5rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <WebinareSvg />
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '1em 0 2em 0',
          padding: '0 1.5rem',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <RecoletaHeading
          css={{
            fontSize: 32,
          }}
        >
          {markdownText(title)}
        </RecoletaHeading>
        <OpenSansParagraph
          css={{
            textAlign: 'center',
            maxWidth: '420px',
            margin: '0 auto',
          }}
        >
          {description}
        </OpenSansParagraph>
      </div>

      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          gap: '1rem',
          '@media (max-width: 768px)': {
            flexDirection: 'column',
          },
        }}
      >
        {items.map((item, i) => WebinareCard(item, i))}
      </div>
    </Container>
  );
};

export default Webinare;
