import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { Row, Col } from 'emotion-flex';
import { MonoFontLabel, OpenSansParagraph } from '../common/typography';
import { SYSTEM_FONTS } from '../../styles/typography';
import presets from '../../styles/presets';
import listItemImage from '../../assets/svgs/homepage/bullet.svg';
import containerBackgroundImage from '../../assets/images/doctors/doctorHero.png';
import { markdownText } from '../../pages/doctors';
import CloseCross from '../../assets/images/close-cross.png';
import TypeformEmbedButton from '../Homepage/2023wk35/TypeformOverlay';
import { ButtonLink } from '../common/Button';
import Container from '../Homepage/Container';
import { useIntl } from 'react-intl';

interface FeatureHeaderProps {
  prefix: string;
  title: string;
  items: (string | JSX.Element)[];
  primaryCTAButtonText: string;
  secondaryCTAButtonText: string;
  secondaryCTAButtonLink: string;
}

interface FeatureHeaderProps {
  prefix: string;
  title: string;
  items: (string | JSX.Element)[];
  primaryCTAButtonText: string;
  secondaryCTAButtonText: string;
  secondaryCTAButtonLink: string;
}

const styles = {
  unorderedList: css`
    list-style: none;
    margin-left: 0;
    margin-top: 24px;
  `,
  listItem: css`
    list-style: none;
    background-image: url(${listItemImage});
    padding-left: 35px;
    padding-top: 4px;
    padding-bottom: 0;
    background-position: left center;
    background-repeat: no-repeat;

    @media (min-width: 1024px) {
      padding-left: 50px;
    }
  `,
  title: css`
    fontFamily: ['Blacker Pro Display'].concat(SYSTEM_FONTS).join(', '),
    fontSize: 32,
    color: '#160B2C',
    marginBottom: 12,

    [presets.md]: { fontSize: 38 },
    [presets.xl]: { fontSize: 60 }, // Increased for larger screens
  `,
};

export default function FeatureHeader({
  prefix,
  title,
  items,
  primaryCTAButtonText,
  secondaryCTAButtonText,
  secondaryCTAButtonLink,
}: FeatureHeaderProps) {
  useEffect(() => {
    const youtubeLinks = document.querySelectorAll('.youtube-link');
    youtubeLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const video = document.createElement('div');
        video.classList.add('video');
        video.innerHTML = `
          <div class="video__inner">
            <div class="video__close"></div>
            <div class="video__content">
              <iframe
                width="560"
                height="315"
                src="${link}?autoplay=1&rel=0"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="Was ist eine DiGA (Digitale Gesundheitsanwendung)?"
              ></iframe>
            </div>
          </div>

          <style>
            .video {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.8);
              z-index: 9999;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .video__inner {
              position: relative;
              width: 100%;
              max-width: 560px;
              height: 100%;
              max-height: 315px;
            }

            .video__close {
              position: absolute;
              top: 20px;
              right: 20px;
              width: 30px;
              height: 30px;
              background-image: url(${CloseCross});
              background-size: cover;
              cursor: pointer;
            }


            .video__content {
              position: relative;
              width: 100%;
              height: 100%;
            }

            .video__content iframe {
              width: 100%;
              height: 100%;
              border-radius: 15px;
            }

            @media (min-width: 768px) {
              .video__inner {
                max-width: 800px;
                max-height: 450px;
              }
            }
          </style>
        `;
        document.body.appendChild(video);

        const closeVideo = document.querySelector('.video__close');
        const videoOverlay = document.querySelector('.video');
        videoOverlay.addEventListener('click', (e) => {
          if (e.target === videoOverlay) {
            video.remove();
          }
        });

        closeVideo.addEventListener('click', () => {
          video.remove();
        });
      });
    });
  }, []);

  const intl = useIntl();

  return (
    <div
      css={{
        maxWidth: '100%',
        boxSizing: 'border-box',
        marginLeft: 'auto',
        marginRight: 'auto',
        overflowX: 'hidden',

        '@media (min-width: 2300px)': {
          maxWidth: '50%',
        },
      }}
    >
      <div className="hubspot-overlay">
        <div className="hubspot-overlay-close"></div>
        <div className="hubspot-overlay-content"></div>
      </div>

      <Row
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          [presets.md]: {
            minHeight: '100vh',
          },
        }}
      >
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          smOrder={0}
          css={{
            marginTop: '2rem',

            [presets.lg]: {
              marginTop: '-10rem',
              paddingLeft: '4rem',
            },
          }}
        >
          <Container>
            <MonoFontLabel css={{ [presets.md]: { fontSize: '0.875rem' } }}>
              {prefix}
            </MonoFontLabel>

            <h1
              dangerouslySetInnerHTML={{ __html: title }}
              css={{
                fontFamily: ['Blacker Pro Display']
                  .concat(SYSTEM_FONTS)
                  .join(', '),
                fontSize: 32,
                color: '#160B2C',
                marginBottom: 12,
                [presets.md]: { fontSize: 38 },
                [presets.xl]: { fontSize: 48 },
              }}
            />

            <ul css={styles.unorderedList}>
              {items &&
                items.map((item, index) => (
                  <li css={styles.listItem} key={index + 'item'}>
                    <MonoFontLabel
                      css={{
                        color: '#000000',
                        [presets.md]: { fontSize: 14 },
                      }}
                    >
                      {typeof item === 'string' ? markdownText(item) : item}
                    </MonoFontLabel>
                  </li>
                ))}
            </ul>

            <OpenSansParagraph
              css={{
                marginTop: 20,
                maxWidth: 400,
                marginBottom: 20,
              }}
            >
              {intl.locale === 'en' &&
                intl.formatMessage({
                  id: 'new.landingpage.disclaimer',
                  defaultMessage:
                    '*Therapy is currently only available in German.',
                })}
            </OpenSansParagraph>

            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <TypeformEmbedButton
                index={2}
                typeformId="W5grl7sJ"
                buttonText={primaryCTAButtonText}
                buttonStyle={{
                  padding: '1rem 1rem',
                  backgroundColor: '#2AAB3C',
                  color: '#ffffff',
                  fontWeight: 700,
                  zIndex: 1,
                }}
              />
              <ButtonLink
                to={secondaryCTAButtonLink}
                css={{
                  marginTop: 5,
                  padding: '1rem 1rem',
                  borderRadius: 8,
                  fontWeight: 'bold',
                  backgroundColor: '#ffffff',
                  zIndex: 1,
                  color: '#2AAB3C',
                }}
                design="bordered"
              >
                {secondaryCTAButtonText}
              </ButtonLink>
            </div>
          </Container>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          smOrder={1}
          css={{
            marginTop: 0,
            '@media (max-width: 768px)': {
              marginTop: '-7rem',
            },
          }}
        >
          <img
            src={containerBackgroundImage}
            alt=""
            css={{
              zIndex: -1,
              position: 'relative',
              borderRadius: 0,

              [presets.xl]: {
                borderRadius: '8%',
              },
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
