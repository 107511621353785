import React, { FC } from 'react';
import { ReactComponent as TopWave } from '../../assets/svgs/doctors/outcomesTopBlue.svg';
import { ReactComponent as BottomWave } from '../../assets/svgs/doctors/outcomesBottomBlue.svg';
import Container from '../Homepage/Container';
import { OpenSansParagraph, RecoletaHeading } from '../common/typography';
import { markdownText } from '../../pages/doctors';
import StudyGraph from '../../assets/svgs/doctors/studyGraph.svg';
import { ReactComponent as Outcome } from '../../assets/svgs/doctors/img-outcomes.svg';
import presets from '../../styles/presets';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ButtonLink } from '../common/Button';

interface StudySection {
  title: string;
  description: string;
  headlineTitle: string;
  headlineDescription: string;
  itemPercentage: string;
  itemDescription: string;
  primaryCTAButtonText: string;
  primaryCTAButtonLink: string;
}

interface StudySectionProps {
  content: StudySection;
}

const OutcomesBox = styled.div(css`
  display: flex;
  flex-direction: column;
  background: #235544;
  border-radius: 10px;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  text-align: center;
  justify-content: space-around;
  padding: 10px 20px;

  ${presets.md} {
    flex-direction: row;
    text-align: left;

    width: 700px;
    margin-top: 16px;
  }
`);

const outcomeHeadingStyle = css`
  font-size: 36px;
  line-height: 32px;
  margin-bottom: 18px;
  margin-top: 18px;
  color: #ffffff;

  #percent {
    font-size: 24px;
  }

  ${presets.md} {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 0;
    margin-top: 0;

    #percent {
      font-size: 28px;
    }
  }

  ${presets.lg} {
    font-size: 44px;
    line-height: 58px;
    margin-top: 0;

    #percent {
      font-size: 32px;
    }
  }

  ${presets.xl} {
    font-size: 48px;
    line-height: 64px;
    margin-top: 0;

    #percent {
      font-size: 36px;
    }
  }
`;

const outcomeParagraphStyle = css`
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 10px;
  opacity: 1;

  ${presets.md} {
    width: 50%;
    font-size: 18px;
    line-height: 26px;
  }

  p {
    margin-bottom: 0;
  }
`;

const StudySection: FC<StudySectionProps> = (data: StudySectionProps) => {
  const {
    title,
    description,
    itemPercentage,
    itemDescription,
    primaryCTAButtonText,
    primaryCTAButtonLink,
  } = data.content;

  return (
    <>
      <TopWave
        width="100vw"
        height="auto"
        css={{
          display: 'block',
          width: '100%',
          height: 'auto',
          marginTop: '-10rem',
          '@media (max-width: 768px)': {
            marginTop: '-12rem%',
          },
        }}
      />
      <div
        css={{
          marginTop: -1,
          backgroundColor: '#d2eedd',
          paddingTop: 30,
          paddingBottom: 30,
        }}
      >
        <Container
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '0 auto',
            maxWidth: 900,
          }}
        >
          <RecoletaHeading
            css={{
              fontSize: 32,
              fontWeight: 500,
            }}
          >
            {markdownText(title)}
          </RecoletaHeading>
          <OpenSansParagraph
            css={{
              textAlign: 'center',
              maxWidth: 500,
            }}
          >
            {markdownText(description)}
          </OpenSansParagraph>
          <img alt="Study Graph" src={StudyGraph} />
          <OutcomesBox>
            <div
              css={{
                width: '10%',
                '@media (max-width: 768px)': {
                  width: '100%',
                },
              }}
            >
              <Outcome />
            </div>
            <RecoletaHeading
              css={[
                outcomeHeadingStyle,
                {
                  width: '25%',
                  '@media (max-width: 768px)': {
                    width: '100%',
                  },
                },
              ]}
            >
              {itemPercentage}
            </RecoletaHeading>
            <OpenSansParagraph
              css={[
                outcomeParagraphStyle,
                {
                  width: '65%',
                  '@media (max-width: 768px)': {
                    width: '100%',
                  },
                },
              ]}
            >
              {markdownText(itemDescription)}
            </OpenSansParagraph>
          </OutcomesBox>
          <ButtonLink
            css={{
              marginTop: '2rem',
              padding: '1rem 1rem',
              borderRadius: 8,
              fontWeight: 'bold',
              backgroundColor: '#2AAB3C',
            }}
            to={primaryCTAButtonLink}
          >
            {primaryCTAButtonText}
          </ButtonLink>
        </Container>
      </div>

      <BottomWave
        width="100%"
        height="83"
        css={{ width: '100%', height: 'auto' }}
      />
    </>
  );
};

export default StudySection;
