import { css } from '@emotion/core';
import React from 'react';
import {
  ContentfulImageHeadingAndShortText,
  ContentfulHeadingAndText,
} from '../../graphql-types';
import CollapsingItemList from '../common/CollapsingItemList';

import { RecoletaHeading } from '../common/typography';
import presets from '../../styles/presets';
import { ButtonLink } from '../common/Button';

interface Props {
  header: ContentfulImageHeadingAndShortText;
  faqItems: ContentfulHeadingAndText[];
  hideCTAButton?: boolean;
}

const headingStyle = css`
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 40px;

  ${presets.md} {
    font-size: 32px;
    line-height: 40px;
  }

  ${presets.lg} {
    max-width: 298px;
    font-size: 40px;
    line-height: 44px;
    text-align: left;
    margin-right: 10px;
  }
`;

const FAQ: React.FC<Props> = ({ header, faqItems, hideCTAButton }) => {
  return (
    <div css={{ padding: 15 }}>
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          marginTop: '50px',
          marginBottom: '50px',
        }}
      >
        <RecoletaHeading css={headingStyle}>{header.heading}</RecoletaHeading>
        <CollapsingItemList
          boxes={faqItems}
          activeColor="#D2EEDD"
          opacity={0.5}
        />
      </div>

      {!hideCTAButton && (
        <div css={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
          <ButtonLink
            to={
              'https://survey.cara.care/to/W5grl7sJ?_gl=1*1jylw6x*_gcl_au*MTc4OTQ2OTEzMi4xNjgyNjA0OTA1LjI3NzQzMDIyNi4xNjgzMjE3NDgwLjE2ODMyMTc0ODM'
            }
            css={css`
              margin-top: 20px;
              width: 300px;
            `}
          >
            Infomaterial anfordern
          </ButtonLink>
        </div>
      )}
    </div>
  );
};

export default FAQ;
